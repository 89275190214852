import { Vue, Prop, Component } from "vue-property-decorator";
import { categorieGiornalista, statiGiornalista } from "../../@types/giornalistiEnums";
import RightPanel from "@/components/RightPanel/index.vue";
import { crogService } from "@/modules/enti/service/crogService";

@Component({
  components: {
    RightPanel
  }
})

export default class filterGiornalisti extends Vue {

  @Prop({ required: true })
  query: giornalisti.queryParams;

  ordiniRegionali: ente.crog[] = [];
  async created() {
    this.ordiniRegionali = await crogService.List();
  }

  get stati(): { key: string, value: string | number }[] {
    if (!this.$can('ANAGRAFICA_GIORNALISTI.viewAllDates'))
      return statiGiornalista.filter(f => f.viewAllDates === this.$can('ANAGRAFICA_GIORNALISTI.viewAllDates'));
    return statiGiornalista
  }

  get categorie(): { key: string, value: string | number }[] {
    if (!this.$can('ANAGRAFICA_GIORNALISTI.viewAll'))
      return categorieGiornalista.filter(f => f.value === 'PRO' || f.value === 'PUB' || f.value === 'PUB_ABI' || f.value === 'PRA_PUB');
    return categorieGiornalista;
  }

  get filterActive(): boolean {
    return (this.query &&
      (
        !!this.query.search ||
        !!this.query.state ||
        !!this.query.codiceFiscale ||
        !!this.query.mail ||
        !!this.query.pec ||
        !!this.query.tessera ||
        !!this.query.categoria ||
        !!this.query.adempiente ||
        !!this.query.blackList ||
        (this.$can('ANAGRAFICA_GIORNALISTI.viewAll') ? !!this.query.anagraficaEnteId : false)
      ));
  }

  public get currentQueryState(): "abilitato" | "pensionato" |"cancellato" |"rinuncia" |"deceduto" {
    return this.query.state;
  }
  public set currentQueryState(stato: "abilitato" | "pensionato" |"cancellato" |"rinuncia" |"deceduto") {
    if (stato && (stato as any) != "") {
      if (stato === "abilitato") {
        this.query.pensionato = false;
        this.query.cancellato = false;
        this.query.rinuncia = false;
        this.query.deceduto = false;
      } else {
        this.query.pensionato = stato === "pensionato" ? true : null;
        this.query.cancellato = stato === "cancellato" ? true : null;
        this.query.rinuncia = stato === "rinuncia" ? true : null;
        this.query.deceduto = stato === "deceduto" ? true : null;
      }
      this.query.state = stato;
    } else {
      this.query.pensionato = null;
      this.query.cancellato = null;
      this.query.rinuncia = null;
      this.query.deceduto = null;
      this.query.state = null;
    }
  }


  handleFilter() {
    this.query.page = 1;
    (this.$refs.filterPanel as any).show = false;
    this.$emit('change', this.query);
  }

  handleClean() {
    (this.$refs.filterPanel as any).show = false;
    this.$emit('clean', {
      search: null,
      state: null,
      codiceFiscale: null,
      mail: null,
      pec: null,
      tessera: null,
      categoria: null,
      adempiente: null,
      anagraficaEnteId: (this.$can('ANAGRAFICA_GIORNALISTI.viewAll') ? null : this.query.anagraficaEnteId),
      orderField: this.query.orderField,
      orderDescending: this.query.orderDescending,
      page: 1,
      take: 20
    } as giornalisti.queryParams);
  }
}