import Vue from 'vue';
import Component from 'vue-class-component';
import settings from '@/settings';
import { store } from '@/store';
import { capitalizeFirstLetter } from '@/utils/utils';

import { anagraficaService } from '../services/anagraficaService';
import { crogService } from '@/modules/enti/service/crogService';
import { creditiService } from '@/modules/offertaFormativa/services/creditiService';

import filterGiornalisti from '../components/filterGiornalisti/filterGiornalisti.vue';
import GiornalistaDetailDialog from '../components/giornalistaDetailDialog/giornalistaDetailDailog.vue';
import ExportAnagraficaRegionaleDialog from '@/modules/anagrafica/components/exportAnagraficaRegionaleDialog/exportAnagraficaRegionaleDialog.vue';
import DialogSelectPeriodoFormativo from '@/modules/offertaFormativa/components/dialog-select-periodo-formativo.vue';
import { periodoFormativoService } from '@/modules/offertaFormativa/services/periodoFormativoService';

import ImportazioneEsenzioni from '@/modules/offertaFormativa/components/Esenzioni/Import/importa.vue';

@Component({
  components: {
    filterGiornalisti,
    GiornalistaDetailDialog,
    DialogSelectPeriodoFormativo,
    ExportAnagraficaRegionaleDialog,
    ImportazioneEsenzioni
  }
})
export default class Anagrafica extends Vue {
  pagination: { pageSizes: number[]; total: number } = {
    pageSizes: [20, 40, 60],
    total: 0
  };

  query: giornalisti.queryParams = {
    search: null,
    categoria: null,
    codiceFiscale: null,
    mail: null,
    pec: null,
    state: null,
    tessera: null,
    adempiente: null,
    anagraficaEnteId: null,
    periodoFormativo: null,
    deceduto: null,
    cancellato: null,
    pensionato: null,
    rinuncia: null,
    orderField: 'NomeCompleto',
    orderDescending: false,
    page: 1,
    take: 20
  };

  ordiniRegionali: ente.crog[] = [];
  async created() {
    if (this.$can('ANAGRAFICA_GIORNALISTI.viewAll') || this.$can('ANAGRAFICA_GIORNALISTI.viewOrg')) {
      if (this.$can('ANAGRAFICA_GIORNALISTI.viewOrg') && !this.$can('ANAGRAFICA_GIORNALISTI.viewAll')) {
        var anagraficaEnteId = store.getters.login.userInfo()?.anagraficaEnteId;
        this.query.anagraficaEnteId = anagraficaEnteId;
      }
      await this.fetchData();
      this.ordiniRegionali = await crogService.List();
    }
  }

  list: giornalisti.summary[] = [];
  manage: giornalisti.summary = null;
  showDetail: boolean = false;

  esenzioniVisibile: boolean = false;

  loading: boolean = false;
  async fetchData() {
    this.loading = true;
    this.pagination.total = await anagraficaService.Count(this.query);
    this.list = (await anagraficaService.List(this.query)).map(m => {
      m.state = this.query.state && (this.query.state as any) !== '' ? this.query.state : m.state;
      return m;
    });
    this.loading = false;
  }

  isInBlacklist(giornalista: giornalisti.summary): boolean {
    return giornalista.contatoreBlacklist >= settings.appSetting.blacklist.assenzePerIngresso;
  }

  async onRemoveFromBlackList(giornalista: giornalisti.summary) {
    const idx = this.list.map(o => o.id).indexOf(giornalista.id);
    this.list[idx].contatoreBlacklist = 0;
    if (idx >= 0) {
      this.list.splice(idx, 1);
    } else {
      this.list.push(giornalista);
    }
    this.manage = null;
  }

  ordine(anagraficaEnteId: string) {
    if (this.ordiniRegionali.length <= 0) return null;
    const ordine = this.ordiniRegionali.find(f => f.id === anagraficaEnteId);
    return ordine?.nome;
  }

  onSelect(value: giornalisti.summary) {
    this.manage = value;
    this.showDetail = true;
  }

  onFilterChange(query) {
    this.query = query;
    this.fetchData();
  }

  onFilterClean(query) {
    this.query = query;
    this.fetchData();
  }

  paginationSizeChange(size) {
    this.query.take = size;
    this.query.page = 1;
    this.fetchData();
  }

  paginationPageChange(page) {
    this.query.page = page;
    this.fetchData();
  }

  sortChange({ column, prop, order }) {
    this.query.orderField = capitalizeFirstLetter(prop);
    this.query.orderDescending = order === 'descending';
    this.fetchData();
  }

  closeEsenzioni() {
    this.esenzioniVisibile = false;
  }

   computedStatoType(value: "abilitato" | "pensionato" | "cancellato" | "rinuncia" | "deceduto") {
    switch (value) {
      case "abilitato":
        return "pubblicato";
      case "pensionato":
        return "inErogazione";
      case "cancellato":
        return "annullato";
      case "rinuncia":
        return "obsoleto";
      case "deceduto":
        return "sospeso";
      default:
        return "primary";
    }
  }

  downloadingAnagraficaCompleta: boolean = false;
  async handleExport() {
    try {
      this.downloadingAnagraficaCompleta = true;
      await anagraficaService.Export({
        search: null,
        categoria: null,
        codiceFiscale: null,
        mail: null,
        pec: null,
        state: null,
        tessera: null,
        adempiente: null,
        anagraficaEnteId: null,
        periodoFormativo: null,
        orderField: null,
        orderDescending: null,
        deceduto: null,
        cancellato: null,
        pensionato: null,
        rinuncia: null,
        page: 0,
        take: -1
      });
      this.downloadingAnagraficaCompleta = false;
    } catch (error) {
      console.log(error);
      this.downloadingAnagraficaCompleta = false;
    }
  }

  exportRegionaleDialog: boolean = false;
  exportRegionale() {
    this.exportRegionaleDialog = true;
  }
  closeExportRegionale() {
    this.exportRegionaleDialog = false;
  }
  ricalcolaAdempienzaSelectPeriodoFormativo: boolean = false;
  ricalcolaAdempienzaLoading: boolean = false;
  async ricalcolaAdempienza(periodoFormativoId) {
    if (this.$can('CREDITI_GIORNALISTA.ricalcolaAdempienzePeriodoFormativo')) {
      const periodoFormativo = await periodoFormativoService.Detail(periodoFormativoId);
      this.$msgbox({
        title: "Attenzione",
        message: `L'operazione di aggiornamento del <i>flag</i> adempiente per ogni giornalista iscritto a https://formazionegiornalisti.it per il periodo formativo ${periodoFormativo.dataInizio}-${periodoFormativo.dataFine} è una <strong>operazione non reversibile</strong> che può richiedere diversi minuti. Continuare?`,
        showCancelButton: true,
        confirmButtonText: "Si",
        cancelButtonText: "Annulla",
        type: "warning",
        dangerouslyUseHTMLString: true
      })
        .then(action => {
          this.ricalcolaAdempienzaLoading = true;
          creditiService.RicalcoloAdempienzePeriodoFormativo(periodoFormativoId).then(() => {
            this.ricalcolaAdempienzaLoading = false;
          }).catch(() => {
            this.ricalcolaAdempienzaLoading = false;
          });
        })
        .catch(() => { });
    }
  }
}
