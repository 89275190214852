import { Vue, Component, Prop } from 'vue-property-decorator';
import { ElForm } from 'element-ui/types/form';
import { periodoFormativoService } from '@/modules/offertaFormativa/services/periodoFormativoService';
import { store } from '@/store';

@Component({})
export default class DialogSelectPeriodoFormativo extends Vue {
  @Prop({default: () => ['chiuso']})
  stato: ('inCompilazione' | 'pubblicato' | 'corrente' | 'chiuso'|'prorogato')[]

  obj : { periodoFormativoId: string } = { periodoFormativoId: null };
  periodiFormativi: periodoFormativo.item[] = [];
  async created() {
    const pfs = store.getters.periodiFormativi.list();
    this.periodiFormativi = pfs.filter(f => this.stato.some(ff => ff === f.stato) ).sort((a, b) => (a.dataFine > b.dataFine ? -1 : 1));
  }
  
  get formRules() {
    return {
      periodoFormativoId: [
        { required: true, trigger: 'blur' },
        { required: true, trigger: 'change'}
      ]
    };
  }

  private async confirm() {
    (this.$refs.form as ElForm)?.validate(async valid => {
      if (valid) {
            this.$emit('confirm', this.obj.periodoFormativoId);
      }
    });
  }
}
