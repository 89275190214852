import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { esenzioniService } from '@/modules/offertaFormativa/services/esenzioniService';
import { periodoFormativoService } from '../../services/periodoFormativoService';

@Component({})

export default class AddSingolaEsenzione extends Vue {

  @Prop({ required: true })
  codiceFiscale: string; 
  
  tipiEsenzioni: esenzioni.itemTipi[] = [];
  esenzioneScelta: esenzioni.addManuale = {
    codiceFiscale: "",
    codicePerImport: null,
    annoInizioPeriodoFormativo: 0
  }; 

  currentPf: periodoFormativo.item = null;

  async created() {
    const res = await esenzioniService.ListTipi();
    this.tipiEsenzioni = res
      .filter(x=>x.macroTipo>100 && x.macroTipo <= 1000)
      .sort((a,b)=> (a.macroTipo - b.macroTipo || a.quantitaNormali - b.quantitaNormali));
    this.esenzioneScelta.codiceFiscale = this.codiceFiscale;
    this.currentPf = await periodoFormativoService.Current();
  }

  salva(row:esenzioni.addManuale){
    this.$confirm(`L'esenzione verrà assegnata<br><strong>nel Periodo Formativo corrente (${this.currentPf.dataInizio} - ${this.currentPf.dataFine})</strong>.<br>
    Per assegnare l'esenzione in un altro Periodo Formativo, usare la funzione di importazione da Excel.<br>
    <strong>Confermi l'assegnazione dell'esenzione?</strong>`, 'Attenzione!', {
      confirmButtonText: 'Assegna',
      cancelButtonText: 'Annulla',
      dangerouslyUseHTMLString: true,
      type: 'warning'
    }).then(async () => {
      try {
        this.esenzioneScelta.annoInizioPeriodoFormativo = this.currentPf.dataInizio;
        const addRes = await esenzioniService.AddManuali({esenzioniRichieste:[this.esenzioneScelta],filename:"-"});
        if(!addRes.results[0].risultato.toLowerCase().startsWith('errore')){
          this.$message({
            message: `Esenzione assegnata con successo.`,
            dangerouslyUseHTMLString: true,
            type: 'success',
            duration: 5 * 1000
          });
        }
        else {
          this.$alert(`L'assegnazione dell'esenzione <br><strong>non è andata a buon fine</strong>.<br>Motivazione:<br>
          <i>${addRes.results[0].risultato}</i>`, 'Attenzione!', {
            confirmButtonText: 'Ok',
            dangerouslyUseHTMLString: true,
            type: 'warning'
          })
        }
        this.$emit("cancelAdd")
      } catch {
        this.$message({
          message: `Errore nell'assegnazione dell'esenzione.`,
          dangerouslyUseHTMLString: true,
          type: 'error',
          duration: 5 * 1000
        });
      }
      this.$emit('cancel');
      return;
    }).catch(()=>{});
  }
}