import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { debounce } from '@/services/_base/debounce';
import { periodoFormativoService } from '@/modules/offertaFormativa/services/periodoFormativoService';
import { anagraficaService } from '@/modules/anagrafica/services/anagraficaService';
import { formatTime } from '@/filters';
import DialogManageEsenzione from '@/modules/offertaFormativa/components/Esenzioni/dialog-manage-esenzioni.vue';
import AddSingolaEsenzione from '@/modules/offertaFormativa/components/Esenzioni/add-singola-esenzione.vue';
import { store } from '@/store';
import { esenzioniService } from '@/modules/offertaFormativa/services/esenzioniService';
import { userService } from '@/modules/users/services/userService';


@Component({
  components: {
    DialogManageEsenzione,
    AddSingolaEsenzione
  }
})
export default class EsenzioniGiornalistaOverview extends Vue {
  showEsenzione: boolean = false;

  @Prop({ required: true })
  anagraficaGiornalistaId: string;

  pagination: { pageSizes: number[]; total: number } = {
    pageSizes: [20, 40, 60],
    total: 0
  };

  showManage: boolean = false;
  showAddSingolaEsenzione: boolean = false;
  canEsenzioni: boolean = this.$can(`ANAGRAFICA_GIORNALISTI.canAddEsenzioni`) || this.$can(`ANAGRAFICA_GIORNALISTI.canAddEsenzioniMassiva`);

  query: esenzioni.listQueryParams = {
    mostraRimossi: true,
    tipoEsenzioneId: null,
    periodoFormativoId: null,
    anagraficaGiornalistaId: null,
    page: 1,
    take: 20
  };


  periodoFormativoNome: string = "";
  list: esenzioni.itemEsenzioni[] = [];
  listTipi: esenzioni.itemTipi[] = [];
  anagrafica: giornalisti.item = null;
  loading: boolean = false;
  activeTab: string = null;

  periodiFormativi: periodoFormativo.item[] = [];
  tipiEsenzioni: esenzioni.itemTipi[] = [];
  esenzioni: esenzioni.itemEsenzioni[] = [];
  esenzioniFocusTriennio: esenzioni.itemEsenzioni[] = [];
  statiEsenzioni: string[] = ["esenzioneAttiva", "esenzioneRimossa", "esenzioneSospesa"];

  esenzioneManage: esenzioni.itemEsenzioni[] = [];
  async created() {
    this.loading = true;
    const pfs = store.getters.periodiFormativi.list();
    await this.fetchData();
    this.query.anagraficaGiornalistaId = this.anagraficaGiornalistaId;
    this.anagrafica = await anagraficaService.Detail(this.anagraficaGiornalistaId);
    
    if (this.anagrafica.dataPrimaIscrizione.getFullYear()) {
      this.periodiFormativi = pfs.filter(f => f.stato === 'chiuso' || f.stato === 'prorogato' || f.stato === 'corrente')
      .filter(f => f.dataInizio >= this.anagrafica.dataPrimaIscrizione.getFullYear() || (f.dataInizio <= this.anagrafica.dataPrimaIscrizione.getFullYear() && f.dataFine >= this.anagrafica.dataPrimaIscrizione.getFullYear()))
      .sort((a, b) => (b.dataFine + b.dataInizio) - (a.dataFine + a.dataInizio));
    }
    this.activeTab = this.periodiFormativi[0].id
  }

  get esenzioniAutomatiche() {
    if (!this.anagrafica || !this.activeTab) return null;
    let check = this.esenzioniFocusTriennio.filter(eft => eft.automatico); // && eft.macrotipo === 2);
    if (check.filter(c => c.macrotipo === 2).length != 0) {
      return "anni30daPrimaIscrizione";
    }
    if (check.filter(c => c.macrotipo === 3).length != 0) {
      return "pensione";
    }
    return null;
  }
  async fetchData() {
    try {
      this.loading = true;
      this.tipiEsenzioni = await esenzioniService.ListTipi();
      this.list = await esenzioniService.ListEsenzioniGiornalista({
        anagraficaGiornalistaId: this.anagraficaGiornalistaId,
        mostraRimossi: this.canEsenzioni
      });
      this.list.sort((a, b) => (b.insertDate.getTime() - a.insertDate.getTime()));
      this.list.map(e => {
        e.automatico = this.tipiEsenzioni.filter(te => te.id == e.tipoEsenzioneId)[0].automatico;
        e.qtaDeontologici = this.tipiEsenzioni.filter(te => te.id == e.tipoEsenzioneId)[0].quantitaDeontologici;
        e.qtaNormali = this.tipiEsenzioni.filter(te => te.id == e.tipoEsenzioneId)[0].quantitaNormali;
        e.macrotipo = this.tipiEsenzioni.filter(te => te.id == e.tipoEsenzioneId)[0].macroTipo;
        e.descrizione = this.tipiEsenzioni.filter(te => te.id == e.tipoEsenzioneId)[0].descrizione;
        var idx = e.disabled == true ? 1 : (e.removedDate == null ? 0 : 1) + (e.automatico == false ? 0 : 1 * (e.removedDate == null ? 0 : 1));
        e.stato = this.statiEsenzioni[idx];
      });

      this.pagination.total = this.list.length;

      this.loading = false;
    } catch (err) {
      this.loading = false;
      this.$message({
        message: 'Errore durante il recupero delle Esenzioni',
        duration: 5 * 1000,
        center: true,
        type: 'error'
      });
    }
  }

  async cancel() {
    this.showManage = false;
    await this.fetchData();
  }

  async cancelAdd() {
    this.showAddSingolaEsenzione = false;
    await this.fetchData();
  }

  @Watch('query.periodoFormativoId')
  async queryChanged(n, o) {
    if (!n) {
      this.list = [];
      this.pagination.total = 0;
    } else {
      if (n !== o) {
        this.fetchData();
      }
    }
  }

  get totaleEsenzione() {
    if (this.list.length <= 0 || !this.activeTab) return 0;
    const res = this.esenzioniFocusTriennio.map(a => a.qtaDeontologici + a.qtaNormali).reduce((a, b) => a + b, 0);
    return res;
  }

  get totaleEsenzioneDeontologici() {
    if (this.list.length <= 0 || !this.activeTab) return 0;
    const res = this.esenzioniFocusTriennio.reduce((a, b) => a + b.qtaDeontologici, 0);
    return res;
  }

  get periodoFormativo() {
    if (!this.periodiFormativi || this.periodiFormativi.length <= 0 || !this.activeTab)
      return null;
    return this.periodiFormativi.find(f => f.id === this.activeTab);
  }

  paginationSizeChange(size) {
    this.query.take = size;
    this.query.page = 1;
    this.fetchData();
  }

  paginationPageChange(page) {
    this.query.page = page;
    this.fetchData();
  }

  async handleInfo(row: esenzioni.itemEsenzioni) {
    if (row.removedDate != null && row.removedBy != "system") {
      const resRemovedBy = await userService.Detail(row.removedBy);
      row.removedBy = resRemovedBy.nomeCompleto;
    }
    const resCreatedBy = await userService.Detail(row.createdBy);
    row.createdBy = resCreatedBy?.nomeCompleto;
    const res = await periodoFormativoService.Detail(row.periodoFormativoId);
    const attuale = await periodoFormativoService.Current();
    if (attuale.id == res.id) {
      this.periodoFormativoNome = `Attuale`
    } else {
      this.periodoFormativoNome = `${res.dataInizio}-${res.dataFine}`;
    }
    this.esenzioneManage = [row];
    this.showManage = true;
  }

}
