import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { anagraficaService } from '@/modules/anagrafica/services/anagraficaService';
import { esenzioniService } from '@/modules/offertaFormativa/services/esenzioniService';
import { periodoFormativoService } from '../../services/periodoFormativoService';
import settings from '@/settings';

@Component({})

export default class DialogManageEsenzione extends Vue {

  @Prop()
  esenzione: esenzioni.itemEsenzioni[];

  @Prop()
  tipiEsenzioni: esenzioni.itemTipi[]

  @Prop()
  statiEsenzioni: string[];

  @Prop()
  periodoFormativoNome: string;

  @Prop({ required: true })
  anagraficaGiornalistaId: string; 

  tipoEsenzione:esenzioni.itemTipi = null;
  altroTipo: string = "";
  notizieEsenzione: string = "Questa esenzione è di tipo";
  showButton: boolean = true;
  messaggioRevoca: string;
  adesso: Date = new Date();
  pf: periodoFormativo.item = null;

  async created() {
    this.pf = await periodoFormativoService.Current();
    this.tipoEsenzione = this.tipiEsenzioni.filter(a=>a.id==this.esenzione[0].tipoEsenzioneId)[0];
    if(this.tipoEsenzione.automatico == true){
      this.altroTipo = this.tipiEsenzioni.filter(a=>a.macroTipo == (this.tipoEsenzione.macroTipo == 3 ? 2 : 3))[0].descrizione;
    }
    
    if(this.tipoEsenzione.automatico == true){
      this.notizieEsenzione += " automatico."
    } else {
      this.notizieEsenzione += " manuale."
    }
    
    const giornalista = await anagraficaService.Detail(this.anagraficaGiornalistaId);
    let condizione30Anni = giornalista.dataPrimaIscrizione.getFullYear() + 30 > this.pf.dataFine;
    let condizione67Anni = giornalista.dataNascita.getFullYear() + 67 > this.pf.dataFine;

    if(this.esenzione[0].updateDate != null && this.tipoEsenzione.automatico == true){
      this.notizieEsenzione += " È stata modificata manualmente."
    }

    if((this.tipoEsenzione.macroTipo == 2 && condizione30Anni ) || this.tipoEsenzione.macroTipo == 1)  //giornalista.dataPrimaIscrizione >= new Date( new Date().setFullYear(this.adesso.getFullYear() - 30))){
    {
      this.showButton = false;
      this.notizieEsenzione += ` La data di prima iscrizione non consente di aggiornare questa esenzione.`;
    }

    if(this.tipoEsenzione.macroTipo == 3 && condizione67Anni) //giornalista.dataNascita >= new Date( new Date().setFullYear(this.adesso.getFullYear() - 67)))
    {
      this.showButton = false;
      this.notizieEsenzione += ` La data di nascita non consente di aggiornare questa esenzione.`;
    }

    if(this.showButton && this.tipoEsenzione.automatico == true)
    {
      this.notizieEsenzione += " La modifica manuale di un'esenzione di tipo automatico impedirà l'aggiornamento automatico delle esenzioni assegnate per età o anzianità di servizio per il resto del Periodo Formativo.";
    }


    this.messaggioRevoca = this.tipoEsenzione.macroTipo == 2 ?`La <strong>revoca</strong> di un'esenzione di tipo<br><strong>${this.tipoEsenzione.descrizione}</strong>,
    <br><strong>non scatena il ripristino</strong> di una di tipo<br><strong>${this.altroTipo}</strong>,<br>laddove fosse stata precedentemente assegnata e revocata` : 
    this.tipoEsenzione.macroTipo == 3 ? `La <strong>revoca</strong> di un'esenzione di tipo<br><strong>${this.tipoEsenzione.descrizione}</strong>,
    <br><strong> scatenerà il ripristino</strong> di una di tipo<br><strong>${this.altroTipo}</strong>,<br>laddove fosse stata precedentemente assegnata e revocata e qualora fosse ancora applicabile` : 
    `Non è possibile ripristinare un'esenzione di tipo ${this.tipoEsenzione.descrizione}: sarà necessario assegnarla nuovamente`;
    
  }

  revoca(row:esenzioni.itemEsenzioni) {
    this.$confirm(`${this.messaggioRevoca}.<br><strong>Confermi la revoca?</strong>`, 'Attenzione!', {
      confirmButtonText: 'Revoca',
      cancelButtonText: 'Annulla',
      dangerouslyUseHTMLString: true,
      type: 'warning'
    }).then(async () => {
      try {
        await esenzioniService.RemoveEsenzione({id:row.id});
        this.$message({
          message: `Esenzione revocata con successo.`,
          dangerouslyUseHTMLString: true,
          type: 'success',
          duration: 5 * 1000
        });
      } catch {
        this.$message({
          message: `Errore nella revoca dell'esenzione.`,
          dangerouslyUseHTMLString: true,
          type: 'error',
          duration: 5 * 1000
        });
      }
      this.$emit('cancel');
      return;
    }).catch(()=>{});
    return;
  }

  ripristina(row:esenzioni.itemEsenzioni){
    this.$confirm(`Ripristinando  un'esenzione di tipo<br><strong>${this.tipoEsenzione.descrizione}</strong>,
    <br>ne verrà revocata una di tipo<br><strong>${this.altroTipo}</strong>,<br>laddove sia stata precedentemente assegnata.<br>
    <strong>Confermi il ripristino?</strong>`, 'Attenzione!', {
      confirmButtonText: 'Ripristina',
      cancelButtonText: 'Annulla',
      dangerouslyUseHTMLString: true,
      type: 'warning'
    }).then(async () => {
      try {
        await esenzioniService.ToggleEsenzione({id:row.id});
        this.$message({
          message: `Esenzione ripristinata con successo.`,
          dangerouslyUseHTMLString: true,
          type: 'success',
          duration: 5 * 1000
        });
      } catch {
        this.$message({
          message: `Errore nel ripristino dell'esenzione.`,
          dangerouslyUseHTMLString: true,
          type: 'error',
          duration: 5 * 1000
        });
      }
      this.$emit('cancel');
      return;
    }).catch(()=>{});
  }
}