import { enteService } from "@/modules/enti/service/enteService";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { anagraficaService } from "../../services/anagraficaService";

import CreditiGiornalistaOverview from '@/modules/offertaFormativa/components/crediti-giornalista-overview.vue'
import EsenzioniGiornalistaOverview from "@/modules/offertaFormativa/components/Esenzioni/esenzioni-giornalista-overview.vue";
import PartecipanteAiCorsiOverview from '@/modules/giornalisti/components/partecipanteAiCorsi/PartecipanteAiCorsi.vue'
import CreditiSpecialiOverview from '@/modules/creditiSpeciali/components/creditiSpecialiOverview/creditiSpecialiOverview.vue'
import settings from "@/settings";
import viewAbsences from "@/modules/profile/components/view-absences.vue";
import { formatTime } from "@/filters";

@Component({
  components: {
    CreditiGiornalistaOverview,
    PartecipanteAiCorsiOverview,
    CreditiSpecialiOverview,
    viewAbsences,
    EsenzioniGiornalistaOverview
  }
})

export default class GiornalistaDetail extends Vue {
  @Prop()
  id: string;

  detail: giornalisti.item = null;

  toUtc(date?: Date | string): Date {
    if (!date) return null;
    
    if (typeof (date) === 'string') {
      date = new Date(date);
    }

    return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getHours(), date.getMinutes(), date.getSeconds())
  }

  activeTab: string = 'generale'
  ente: ente.item = null;

  created() {
    this.fetchDetail()
  }

  @Watch("id")
  changeId(n, o) {
    if (n && n !== o) {
      this.fetchDetail();
    }
  }

  async fetchDetail() {
    this.detail = await anagraficaService.Detail(this.id);
    this.ente = await enteService.Detail(this.detail.anagraficaEnteId);
  }

  get nomeEnte() {
    return this.ente?.nome
  }

  get isGiornalistaInBlacklist() {
    return this.detail?.dataIngressoBlacklist && this.detail?.contatoreBlacklist >= settings.appSetting.blacklist.assenzePerIngresso;
  }

  get fullLocation(): string {
    const location = []
    if (this.detail.cap) location.push(this.detail.cap.trim())
    if (this.detail.comune) location.push(this.detail.comune.trim())
    if (this.detail.provincia) location.push(this.detail.provincia.trim())
    if (this.detail.nazione) location.push(this.detail.nazione.trim())
    return location.join(', ')
  }
  async clearBlackList() {
    await this.fetchDetail()
    this.$emit("removeBlacklist")
  }

}