import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

import GiornalistaDetail from '../giornalistaDetail/giornalistaDetail.vue';

import settings from "@/settings";
import { anagraficaService } from "../../services/anagraficaService";

@Component({
  components: {
    GiornalistaDetail
  }
})

export default class GiornalistaDetailDialog extends Vue {
  @Prop()
  id: string;
  manage: giornalisti.item = null;

  async created() {
    this.manage = await anagraficaService.Detail(this.id)
  }

  get isInBlacklist(): boolean {
    return this.manage.contatoreBlacklist >= settings.appSetting.blacklist.assenzePerIngresso;
  }


  showRimozioneBlacklist: boolean = false;
  motivoRimozioneBlacklist: string = null;
  async confermaRimozione() {

    this.manage.contatoreBlacklist = 0;
    this.$emit("removeFromBlacklist", this.manage)
  }

  closeRimozioneBlacklist() {
    this.showRimozioneBlacklist = !this.showRimozioneBlacklist;
    this.motivoRimozioneBlacklist = null;
  }
}