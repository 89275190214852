import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";


import settings from "@/settings";
import { anagraficaService } from "../../services/anagraficaService";
import { crogService } from "@/modules/enti/service/crogService";
import { periodoFormativoService } from "@/modules/offertaFormativa/services/periodoFormativoService";
import { ElForm } from "element-ui/types/form";
import { userService } from "@/modules/users/services/userService";
import { loginStore } from "@/store/modules/login.store";
import { authService } from "@/modules/account/services/authService";

@Component({
})
export default class ExportAnagraficaRegionaleDialog extends Vue {
  selected: string = null;
  periodoFormativo: string = null;
  obj: { ordineRegionale: string, periodoFormativo: string } = { ordineRegionale: null, periodoFormativo: null }
  user: user.summary = null;
  periodi: periodoFormativo.item[] = [];
  ordiniRegionali: ente.crog[] = [];
  async created() {
    this.user = loginStore.state.userInfo || await authService.WhoAmI();
    this.periodi = await periodoFormativoService.List();
    this.ordiniRegionali = await crogService.List();
  }
  downloading: boolean = false
  get ordiniRegionaliFiltered() {
    if (this.$can('ANAGRAFICA_ENTI.canExportGiornalistiODG')) {
      return this.ordiniRegionali
    }
    else {
      return this.ordiniRegionali.filter(x => x.id == this.user.anagraficaEnteId)
    }
  }
  get validationRules() {
    return {
      ordineRegionale: [
        { required: true, trigger: 'blur' },
        { required: true, trigger: 'change' }
      ],
      periodoFormativo: [
        { required: true, trigger: 'blur' },
        { required: true, trigger: 'change' }
      ],
    }
  }
  async handleExportRegionale() {
    (this.$refs.form as ElForm)?.validate(async valid => {
      if (valid) {
        try {
          this.downloading = true;
          try {
            await anagraficaService.ExportAdempienze({
              search: null,
              categoria: null,
              codiceFiscale: null,
              mail: null,
              pec: null,
              state: null,
              tessera: null,
              adempiente: null,
              anagraficaEnteId: this.obj.ordineRegionale,
              periodoFormativo: this.obj.periodoFormativo,
              orderField: null,
              orderDescending: null,
              deceduto: null,
              cancellato: null,
              pensionato: null,
              rinuncia: null,
              page: 0,
              take: -1
            }, this.periodi.find(f => f.id === this.obj.periodoFormativo));
            this.downloading = false;
          } catch (error) {
            console.log(error);
            this.downloading = false;
          }
        } catch (e) {
          console.log(e);
          this.downloading = false;
        }
      }
    })

  }
}