import { Deferred } from "@/utils/Deferred";
import moment from "moment";
import Vue from "vue";

class ImportaEsenzioniUtils extends Vue {
  async parseEsenzione(row: sheets.esenzione, index: number, esenzione: esenzioni.addManuale[]) {
    var defer = new Deferred<esenzioni.addManuale>();
    setTimeout(() => {

      let cf: string = "";
      if (row && row.codiceFiscale !== null && row.codiceFiscale !== undefined) {
        if (typeof (row.codiceFiscale) === "string") {
          cf = row.codiceFiscale.toUpperCase();
        } else {
          cf = null;
        }
      }

      let cpi: number = 0;
      if (row && row.codicePerImport !== null && row.codicePerImport !== undefined) {
        if (typeof (row.codicePerImport) === "number") {
          cpi = row.codicePerImport;
        } else {
          cpi = -1;
        }
      }

      let parseRow = {
        codicePerImport: cpi,
        codiceFiscale: cf
      } as esenzioni.addManuale;

      defer.resolve(row);
    });
    return defer.promise;
  }

  normalize(value: string | number): string {
    if (!value) return null;

    if (typeof value === "number") {
      value = value.toString();
    }
    return value
      .toString()
      .toLowerCase()
      .replace(/ /g, "_")
      .replace(/-/g, "_");
  }

}

export const importaEsenzioniUtils = new ImportaEsenzioniUtils();