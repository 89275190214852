export const statiGiornalista = [
  {key:"abilitato", value: 0, viewAllDates: false},
  {key:"pensionato", value: 1, viewAllDates: false},
  {key:"cancellato", value: 2, viewAllDates: true},
  {key:"rinuncia", value: 3, viewAllDates: true},
  {key:"deceduto", value: 4, viewAllDates: true},
]
export const categorieGiornalista = [
  {key: "Professionista", value: "PRO"},
  {key: "Pubblicista", value: "PUB"},
  {key: "Pubblicista Abilitato", value: "PUB_ABI"},
  {key: "Praticante", value: "PRA"},
  {key: "Praticante Pubblicista", value: "PRA_PUB"},
  {key: "Speciale", value: "SPE"},
  {key: "Stranieri", value: "STRA"},
]